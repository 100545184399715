import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
//
//
//
//
//
export default {
  name: 'Refresh',
  data: function data() {
    return {};
  },
  beforeRouteEnter: function beforeRouteEnter(to, from, next) {
    next(function (vm) {
      vm.$router.replace(from.path);
    });
  }
};